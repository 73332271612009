<template>
<div>
    <div class="row" style="margin-top: 20px;">
        <div class="col-md-6">
            <h3 class="page-header" v-if="leadsedit==null">
                <i class="fa fa fa-users animated bounceInDown show-info"></i>New Lead
            </h3>
            <h3 class="page-header" v-if="leadsedit!=null">
                <i class="fa fa fa-users animated bounceInDown show-info"></i>Edit Lead 
            </h3>
        </div>
        <div class="col-md-6" v-if="leadsedit!=null">
            <div class="row" style="padding-top: 18px; justify-content: flex-end;">
                <div class="col-md-2" style="margin-right: 25px;">
                    <button class="btn btn-primary" @click="back()"><i class="fa fa-backward"></i> Back</button>
                </div>
            </div>
        </div>
    </div>
    <div class="flex-row">
        <div class="col-md-12">
            <div class="panel panel-cascade">
                <div class="info-box bg-primary text-white">
                    <div class="info-icon bg-warning-dark">
                        <i class="fa fa fa-user fa-2x"></i>
                    </div>
                    <div class="info-details">
                        <h4>Customer Details</h4>
                    </div>
                </div>
                <div class="panel-body" >
                    <div class="form-horizontal cascde-forms">
                        <!-- <div class="form-group flex-row">
                            <label class="col-lg-4 col-md-3 control-label">Select Customer<span style="color:red">*</span> </label>
                            <div class="col-lg-8 col-md-9">
                                <div class="flex-around-row gap-20">
                                    <div>
                                        <label for="cust">New Customer</label>
                                        <input style="margin-left:10px ;" type="radio" name="customertype" value="1" id="cust" v-model="customertype">
                                    </div>
                                    <div>
                                        <label for="cust2">Existing Customer</label>
                                        <input style="margin-left:10px ;" type="radio" name="customertype" value="2" id="cust2" v-model="customertype">
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="form-group flex-row" >
                            <label class="col-lg-4 col-md-3 control-label">Customer Refrence<span style="color:red">*</span> </label>
                            <div class="col-lg-8 col-md-9">
                                <select v-model="refrenceid" class="form-control delradius form-cascade-control"
                                    required>
                                    <option :value="0">----Select---</option>
                                    <option v-for="option in getcompanymastertype(COMPANY_CUSTOMER_REFRENCE)"
                                        v-bind:key="option.labelid" v-bind:value="option.labelid"
                                        v-text="option.label" />
                                </select>
                            </div>
                        </div>
                        <div class="form-group flex-row" >
                            <label class="col-lg-4 col-md-3 control-label">Customer Refrence Remark Optional<span style="color:red">*</span> </label>
                            <div class="col-lg-8 col-md-9">
                                <input class="form-control form-cascade-control input-small" v-model="refrence" type="text" />
                                <!-- <span class="error" v-if="!$v.customername.minLength">Name should be greater than 2</span> -->
                            </div>
                        </div> 
                        <div class="form-group flex-row" >
                            <label class="col-lg-4 col-md-3 control-label">Customer Name<span style="color:red">*</span> </label>
                            <div class="col-lg-8 col-md-9">
                                <input class="form-control form-cascade-control input-small" v-model="customername" type="text" />
                                <!-- <span class="error" v-if="!$v.customername.minLength">Name should be greater than 2</span> -->
                            </div>
                        </div>
                        <div class="form-group flex-row" >
                            <label class="col-lg-4 col-md-3 control-label">Customer Mobile No.<span style="color:red">*</span> </label>
                            <div class="col-lg-8 col-md-9" :class="{ 'form-group--error': $v.mobileno.$error }">
                                <input class="form-control form-cascade-control input-small" v-model="$v.mobileno.$model" type="number" @change="mobileduplicate()" @input="mobileduplicate()"/>
                                <!-- <span class="error" v-if="!$v.newmobile.mobile.required">Field is required</span> -->
                                <span class="error" v-if="!$v.mobileno.maxLength">Mobile No. should not exceed {{$v.mobileno.$params.maxLength.max}} letters.</span>
                                <span class="error" v-if="!$v.mobileno.minLength">Mobile No. should of minimum 10 digit</span>
                                <span class="error" v-if="checkduplicate">Mobile No. Already Exists</span>
                            </div>
                        </div>
                        <div class="form-group flex-row" >
                            <label class="col-lg-4 col-md-3 control-label">Customer Alternate Mobile No.<span style="color:red">*</span> </label>
                            <div class="col-lg-8 col-md-9" :class="{ 'form-group--error': $v.alternatemobile.$error }">
                                <input class="form-control form-cascade-control input-small" v-model="$v.alternatemobile.$model" type="number" @change="alternatemobileduplicate()" @input="alternatemobileduplicate()"/>
                                <!-- <span class="error" v-if="!$v.newmobile.mobile.required">Field is required</span> -->
                                <span class="error" v-if="!$v.alternatemobile.maxLength">Mobile No. should not exceed {{$v.alternatemobile.$params.maxLength.max}} letters.</span>
                                <span class="error" v-if="!$v.alternatemobile.minLength">Mobile No. should of minimum 10 digit</span>
                                <span class="error" v-if="checkduplicatealternate">Mobile No. Already Exists</span>
                            </div>
                        </div>
                        <div class="form-group flex-row">
                            <label class="col-lg-4 col-md-3 control-label">Customer Address<span style="color:red">*</span> </label>
                            <div class="col-lg-8 col-md-9">
                                <input class="form-control form-cascade-control input-small" v-model="customeraddress" type="text" />
                                </div>
                        </div>
                        <div class="form-group flex-row" >
                            <label class="col-lg-4 col-md-3 control-label">Date of Birth<span style="color:red">*</span> </label>
                            <div class="col-lg-8 col-md-9">
                                <input class="form-control form-cascade-control input-small" v-model="dob" type="date" />
                                <!-- <span class="error" v-if="!$v.customername.minLength">Name should be greater than 2</span> -->
                            </div>
                        </div> 
                        <div class="form-group flex-row" v-if="customertype==1">
                            <label class="col-lg-4 col-md-3 control-label">Introducer VSDIGI ID<span style="color:red">*</span> </label>
                            <div class="col-lg-8 col-md-9">
                                <input class="form-control form-cascade-control input-small" v-model="advisor" type="text" value="" @input="getAdvisor()"/>
                                <p v-if="usernotfound" style="color:red">Introducer Not Found</p>
                                <div class="form-group row" v-if="advisorfound!=null">
                                    <div class="col-md-2" style="margin-top:5px;" v-if="advisorfound!=null">
                                        <div class="referal-text" ><strong>{{ advisorfound.name }}</strong></div>
                                    </div>
                                    <div class="col-md-4" style="margin-top:5px;" v-if="advisorfound!=null">
                                        <display-mobiles :mobiles="advisorfound.mobiles" class="referal-text"></display-mobiles>
                                    </div> <!-- input-group.// -->					    
                                    <div class="col-md-6" style="margin-top:5px;" v-if="advisorfound!=null">
                                        <display-first-address :addresses="advisorfound.addressess" class="referal-text"></display-first-address>
                                    </div> <!-- input-group.// -->	
                                </div>
                            </div>
                        </div>
                        <div class="form-group flex-row">
                            <label class="col-lg-4 col-md-3 control-label">Ratings <i class='bx bxs-star' style='color:#eff300'  ></i></label>
                            <div class="col-lg-8 col-md-9">
                                <select name="" id="" class="form-control form-cascade-control input-small"  v-model="ratings">
                                    <option :value="0">--Select--</option>
                                    <option :value="1">--1--</option>
                                    <option :value="2">--2--</option>
                                    <option :value="3">--3--</option>
                                    <option :value="4">--4--</option>
                                    <option :value="5">--5--</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group flex-row">
                            <label class="col-lg-4 col-md-3 control-label">Remark</label>
                            <div class="col-lg-8 col-md-9">
                                <textarea class="form-control form-cascade-control input-small" cols="20" v-model="remark" rows="2">
                                </textarea>
                            </div>
                        </div>
                        
                </div>
            </div>
        </div>
        </div>
        <div class=" col-md-12 text-center">
            <input type="submit" value="Submit" style="width:20%" class="btn bg-primary text-white btn-md" @click="submit()">
        </div>
        
    </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
// import ReferralSelector from '../../components/user/ReferralSelector.vue'
import DisplayMobiles from '../../components/mobile/DisplayMobiles.vue'
import DisplayFirstAddress from '../../components/address/DisplayFirstAddress.vue'
import { maxLength,minLength } from 'vuelidate/lib/validators'
import Constants from '../../components/utilities/Constants.vue'
export default{
    mixins:[Constants],
    components:{DisplayMobiles,DisplayFirstAddress},
    data(){
        return{
            followedid:'',
            requesttype:5,
            remark:'',
            leadtype:0,
            id:0,
            leaddate:'',
            checkduplicate:false,
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD', // Uses 'iso' if missing
            },
            usernotfound:false,
            customername:'',
            existcustomername:'',
            founduser:[],
            brandid:0,
            products:[],
            parentid:0,
            category_id:0,
            brands:[],
            productid:0,
            advisor:'',
            advisorfound:[],
            customertype:0,
            customeraddress:'',
            mobileno:'',
            alternatemobile:'',
            refrence:'',
            checkduplicatealternate:false,
            ratings:0,
            refrenceid:0,
            dob:''
        }
    },
    validations:{
        mobileno:{
            minLength:minLength(10),
            maxLength:maxLength(10)
        },
        alternatemobile:{
            minLength:minLength(10),
            maxLength:maxLength(10)
        },
        // customername:{
        //     minLength:minLength(2)
        // }
    },
    computed:{
        ...mapGetters(['employees','referraluser','loggedinuser','categories','leadsedit','edititem']),
        parentcategory(){
            if(this.categories!=null){
                return this.categories.filter(block=>block.parent_id==0)
            }
            return [];
        },
        subcategory(){
            if(this.categories!=null){
                return this.categories.filter(block=>block.parent_id==this.parentid)
            }
            return [];
        },
        /*brands(){
            let attribute = this.attributes.find(block=>block.id==2)
            return attribute
        },*/
        predefinedvalues(){
            if(this.brands!=null && this.brands.predefinedvalues!=null){
                return this.brands.predefinedvalues
            }
            return [];
        },
        getval(value){
            return value
        },

        filters(){
            return this.categories.filter(item => item.parent_id != 0 && item.parent_id != null)
        },
        selectedproduct(){
            if(this.products!=null){
                return  this.products.find(block=>block.id==this.productid)
            }
            return [];
        },
    },
    mounted(){
        this.$store.dispatch('fetchcompanymaster')
        this.initializedata()
    },
    methods:{
        getUser(){
            let param={generatedid:this.existcustomername}
            this.$http.post('api/user/getuserbygeneratedid',param)
            .then((response) => this.processGetUSerResponse(response.data))
            .catch((err) => {
                console.log('', err)
            });
        },
        processGetUSerResponse(data){
            if(data!=null){
                this.founduser=data
                // this.usernotfound=false
            }else{
                // this.usernotfound=true
            }
        },
        getAdvisor(){
            let param={generatedid:this.advisor}
            this.$http.post('api/user/getuserbygeneratedid',param)
            .then((response) => this.processAdvisorUSerResponse(response.data))
            .catch((err) => {
                console.log('', err)
            });
        },
        processAdvisorUSerResponse(data){
            if(data!=null){
                this.advisorfound=data
                // this.usernotfound=false
            }else{
                // this.usernotfound=true
            }
        },
        mobileduplicate(){
            let param={mobileno:this.mobileno,alternatemobile:this.alternatemobile,requesttype:5}
            this.$http.post('api/leads/check/mobile',param) 
            .then((response) => this.processCheckResponse(response.data))
            .catch((err) => {
                console.log('', err)
            });
        },
        alternatemobileduplicate(){
            let param={alternatemobile:this.alternatemobile,requesttype:5}
            this.$http.post('api/leads/check/mobile',param) 
            .then((response) => this.processCheckResponseAlternate(response.data))
            .catch((err) => {
                console.log('', err)
            });
        },
        processCheckResponse(data){
            if(data==true){
                this.checkduplicate=true
            }else{
                this.checkduplicate=false
            }
        },
        processCheckResponseAlternate(data){
            if(data==true){
                this.checkduplicatealternate=true
            }else{
                this.checkduplicatealternate=false
            }
        },
        
        initializedata(){
            if(this.leadsedit!=null){
                this.customername=this.leadsedit.customername
                this.requesttype=5
                this.remark=this.leadsedit.remark
                this.id=this.leadsedit.id
                this.leadtype=0
                this.leaddate=this.leadsedit.leaddate
                this.mobileno=this.leadsedit.customermobile
                this.alternatemobile=this.leadsedit.alternatemobile
                this.customeraddress=this.leadsedit.customeraddress
                this.refrence=this.leadsedit.refrence
                this.ratings=this.leadsedit.ratings
                this.refrenceid=this.leadsedit.refrenceid
                this.dob=this.leadsedit.dob
            }else{
                this.followedid=''
                this.customername=''
                this.requesttype=5
                this.remark=''
                this.leadtype=0
                this.leaddate=''
                this.founduser=[]
                this.advisorfound=[]
                this.advisor=''
                this.existcustomername=''
                this.mobileno='',
                this.alternatemobile=''
                this.customeraddress=''
                this.products=[]
                this.parentid=0
                this.brandid=0
                this.category_id=0
                this.refrence=''
                this.ratings=0
                this.refrenceid=0
                this.dob=''
            }
        },
        submit(){
            let advisorcheck=false
            if(this.customertype==1){
                advisorcheck=true
                if(this.advisorfound.id>0){
                    advisorcheck=false
                }
            }
            if(this.checkduplicate==false && advisorcheck!=true){
                this.$notify({text:'Submitted',type:'warn'})
                let param = {id:this.id,
                    advisorid:this.loggedinuser?.id,
                    requesttype:this.requesttype,
                    remark:this.remark,
                    isdispose:0,leaddate:this.leaddate,
                    customername:this.customername,
                    customeraddress:this.customeraddress,
                    mobileno:this.mobileno,
                    alternatemobile:this.alternatemobile,
                    refrence:this.refrence,
                    followedid:this.loggedinuser?.id,
                    ratings:this.ratings,
                    refrenceid:this.refrenceid,
                    dob:this.dob
                }
                this.$store.commit('assignloadingstatus',1)
                this.$http.post('api/leads/createupdate',param)
                .then((response) => this.processLeadResponse(response.data))
                .catch((err) => {
                    console.log('', err)
                });
            }else{
                this.$notify({text:'Please Avoid the Error Mention in the Form',type:'error'})
            }
        },
        processLeadResponse(){
            this.$store.commit('assignleadsedit',[])
            this.initializedata();
            this.$notify({text:'Submitted Successfully',type:'success'})
            this.$store.commit('assignloadingstatus',0)
        },
        
        fetchbrands(){
            
            let param = {parentcategoryid:this.category_id,attributeid:2}
            window.axios.post('api/attribute/values/fetchbycategory',param)
                .then(response=>this.processAvailableBrands(response.data))
                .catch(error=>console.log(error));
        },
        processAvailableBrands(data){
            this.brands = data
        },
        fetchproducts(){
            
            let param = {parentcategoryid:this.category_id,attributeid:2,attributevalueids:this.brandid}
            window.axios.post('api/products/fetchbyfilters',param)
                .then(response=>this.processProductsResponse(response.data))
                .catch(error=>console.log(error));
        },
        processProductsResponse(data){
            this.products = data
        },
        back(){
            this.$store.commit('assignleadsedit',[])
            this.$store.commit('assignviewno',1)
        }
    }
}
</script>
<style>
.flex-row{
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    flex-wrap: wrap;
}
</style>